import { Injectable } from '@angular/core';
import { Settings, ClientSettings } from './app.settings.model';
import {environment} from "../environments/environment";
import {environment as environmentProd} from "../environments/environment.prod";
import { isDevMode } from '@angular/core';

@Injectable()
export class AppSettings {
    public settings = new Settings(
        'Gradus',       //theme name
        true,           //loadingSpinner
        true,           //fixedHeader
        true,           //sidenavIsOpened
        true,           //sidenavIsPinned
        true,           //sidenavUserBlock
        'vertical',     //horizontal , vertical
        'default',      //default, compact, mini
        'teal-light', //indigo-light, teal-light, red-light, blue-dark, green-dark, pink-dark
        false,          // true = rtl, false = ltr
    )
}

@Injectable()
export class AppClientSettings {
    public settings = new ClientSettings(isDevMode() ? environment.baseApiUrl : environmentProd.baseApiUrl, "http://localhost:6869")
}
