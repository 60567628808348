import { Component, Inject, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import {HttpClientModule, HttpClient, HttpRequest, HttpResponse, HttpEventType} from '@angular/common/http';
@Component({
  selector: 'app-file-upload',
  templateUrl: './file-upload.component.html'
})

export class FileUploadComponent {
  percentDone: number;
  uploadSuccess: boolean = true;
  @Input() apiUpload: string;
  @Input() defaultSrc: string;
  @Input() multiple: boolean = true;
  @Input() accept: string;
  @Output() outData: EventEmitter<any> = new EventEmitter<any>();
  constructor(
    public dialogRef: MatDialogRef<FileUploadComponent>,
    private http: HttpClient,
    @Inject(MAT_DIALOG_DATA) 
    public message: string) { 
      this.defaultSrc = `'`+encodeURIComponent(this.defaultSrc ?? '')+`'`;
    }

    onClick(status:boolean): void {
    this.dialogRef.close(status);
   
  }

  upload(files: File[]){
    //pick from one of the 4 styles of file uploads below
    this.uploadAndProgressSingle(files);
  }

  basicUpload(files: File[]){
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file', f))
    this.http.post(this.apiUpload, formData)
      .subscribe(event => {  
      })
  }
  
  //this will fail since file.io dosen't accept this type of upload
  //but it is still possible to upload a file with this style
  basicUploadSingle(file: File){    
    this.http.post(this.apiUpload, file)
      .subscribe(event => {  
        
      })
  }
  
  uploadAndProgress(files: File[]){
  
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file',f))
    
    this.http.post(this.apiUpload, formData, {reportProgress: true, observe: 'events'})
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round(100 * event.loaded / (event.total ?? 1));
        } else if (event instanceof HttpResponse) {
          this.uploadSuccess = true;
        }
    });
  }
  @ViewChild('file', {static: false}) fileX1: ElementRef;
  @ViewChild('file2', {static: false}) fileX2: ElementRef;

  //this will fail since file.io dosen't accept this type of upload
  //but it is still possible to upload a file with this style
  uploadAndProgressSingle(files: File[]){    
    this.uploadSuccess =false;
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file',f))

    this.http.post(this.apiUpload, formData, {reportProgress: true, observe: 'events'}).pipe()
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round(100 * event.loaded / (event.total ?? 1));
        } else if (event instanceof HttpResponse) {
          this.uploadSuccess = true;
          this.outData.emit(event.body)
          this.fileX1.nativeElement.value = null;
          this.fileX2.nativeElement.value = null;
        }
    });
  }
}