import { Component, OnInit, ViewEncapsulation, ViewChild } from '@angular/core';
import { AppSettings } from '../../../app.settings';
import { Settings } from '../../../app.settings.model';
import { MenuService } from '../menu/menu.service';
import { AccountData } from 'src/app/admin/account/account.model';
import { AppStorage } from 'src/app/app.storage';
import { Router } from '@angular/router';

@Component({
  selector: 'app-sidenav',
  templateUrl: './sidenav.component.html',
  styleUrls: ['./sidenav.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class SidenavComponent implements OnInit {
  public userImage= '../assets/img/users/default-user.jpg';
  userInfo: AccountData;
  public menuItems: Array<any>;
  public settings: Settings;
  public categoryData: any;
  constructor(
    public appSettings:AppSettings,
     public router: Router,
     public appStorage: AppStorage,
    public menuService:MenuService
    ){
      this.settings = this.appSettings.settings;
      this.categoryData = this.appStorage.getCategoryData();
  }

  ngOnInit() {
    this.userInfo = this.appStorage.getAccountInfo();
    this.menuItems = this.menuService.getVerticalMenuItems();
  }

  logout() {
    this.appStorage.removeAccountInfo();
    this.router.navigate(["login"]);
  }

  public closeSubMenus(){
    let menu = document.getElementById("vertical-menu");
    if(menu){
      for (let i = 0; i < menu.children[0].children.length; i++) {
        let child = menu.children[0].children[i];
        if(child){
          if(child.children[0].classList.contains('expanded')){
              child.children[0].classList.remove('expanded');
              child.children[1].classList.remove('show');
          }
        }
      }
    }
  }

}
