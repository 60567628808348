import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { AppStorage } from '../app.storage'

@Injectable({ providedIn: 'root' })
export class AuthGuard implements CanActivate {
    constructor(
        private router: Router,
        public appStorage : AppStorage
    ) { }

    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        const currentUser = this.appStorage.getAccountInfo();
        if ((currentUser && currentUser.token) || location.pathname.startsWith('/user/')) {
            return true;
        }
        this.router.navigate(['/login']);
        return true;
    }
}