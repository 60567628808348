import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AppStorage } from '../app.storage'
import { AppSettings, AppClientSettings } from '../app.settings';
@Injectable()
export class JwtInterceptor implements HttpInterceptor {
    constructor(
        public appStorage : AppStorage,
        public appClientSettings: AppClientSettings) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        // add auth header with jwt if user is logged in and request is to api url
        const currentUser = this.appStorage.getAccountInfo();
        const isLoggedIn = currentUser && currentUser.token;
        const isApiUrl = request.url.startsWith(this.appClientSettings.settings.serverAPI);
        if (isLoggedIn && isApiUrl) {
            request = request.clone({
                setHeaders: {
                    Authorization: `Bearer ${currentUser.token}`
                }
            });
        }

        if (request.method === 'POST' || request.method === 'PUT') {
           //  this.shiftDates(request.body);
        }

        return next.handle(request);
    }
    shiftDates(body) {
        if (body === null || body === undefined) {
            return body;
        }
    
        if (typeof body !== 'object') {
            return body;
        }
    
        for (const key of Object.keys(body)) {
            const value = body[key];
            if (value instanceof Date) {
                body[key] = new Date(Date.UTC(value.getFullYear(), value.getMonth(), value.getDate(), value.getHours(), value.getMinutes(),value.getSeconds()));
            } else if (typeof value === 'object') {
                this.shiftDates(value);
            }
       
        }
    }
}