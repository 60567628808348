import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { AppSettings } from '../../app.settings';
import { Settings } from '../../app.settings.model';

@Component({
  selector: 'app-redirect',
  templateUrl: './redirect.component.html'
})
export class RedirectComponent  implements OnInit{
  public settings: Settings;
  constructor(public appSettings:AppSettings, private route: ActivatedRoute, private router: Router) {
    this.settings = this.appSettings.settings; 
  }
  ngOnInit(): void {
    this.route.queryParams.subscribe(params => {
      var newXX = params['newredirect'];
      if(newXX !=null && newXX != '' && newXX!= undefined)
      {
        this.router.navigateByUrl(newXX);
        console.log(newXX);
      }
      else
      {
        this.router.navigateByUrl('/');
      }
  });
  }
}