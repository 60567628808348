import { Directive, ElementRef, HostListener, Input, OnInit } from '@angular/core';
import { AppStorage } from './app.storage';

@Directive({
  selector: '[roles-is]'
})
export class CheckRolesDirective implements OnInit {
  @Input('roles-is') roleValues = '0';
  acctions: Number[];
  elx: ElementRef
  constructor(el: ElementRef, public appStorage: AppStorage) { 
    this.elx = el; 
  }
 
  ngOnInit(): void {
    this.checkRoles(this.elx);
  }

  checkRoles(el: ElementRef)
  {
    var ids = this.roleValues.split(',');
    this.acctions = this.appStorage.getAccountInfo().roleActions.map(x=>x.id);
    if(ids.length ==0)
    {
      el.nativeElement.style.display = 'none';
    }
    else if(!this.appStorage.getAccountInfo().isSuperAdmin)
    {
      var kt = this.acctions.find(x=> (ids.indexOf(x.toString()) >= 0));
      if( kt == null || kt == undefined)
      {
        el.nativeElement.style.display = 'none';
      }
    }
    else{}
  }
}