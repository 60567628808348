import { Injectable } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { Router } from '@angular/router';
import { AppStorage} from './../app.storage'
import { MatSnackBar } from '@angular/material/snack-bar';
@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    constructor(
        public router: Router,
         public appStorage:AppStorage,
         public snackBar: MatSnackBar,
         ) { }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
  
        return next.handle(request).pipe(catchError(err => {
            if (this.appStorage.getAccountInfo()==null || this.appStorage.getAccountInfo()==undefined) {
                this.appStorage.removeAccountInfo();
                this.appStorage.removeCategoryData();
                //, { queryParams: { returnUrl: location.href } }
                this.router.navigate(['login']);
            }
            else if(err.status === 401)
            {
                // this.appStorage.removeAccountInfo();
                // this.appStorage.removeCategoryData();
                this.router.navigate(['not-enough-access']);
            }
            else if(err.status === 400)
            {
                this.snackBar.open(JSON.stringify(err.error), "Lỗi thiếu input",{ duration: 10000 });
            }
            else if (err.status === 403) {
                this.appStorage.removeAccountInfo();
                this.appStorage.removeCategoryData();
                this.router.navigate(['login']);
            }
            const error =  err.statusText || err.error.message;
            return throwError(error);
        }))
    }
}