import { Component, Inject, Input, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpClient, HttpResponse, HttpEventType } from '@angular/common/http';
import { ApiResponseData, FileDataDTO } from 'src/app/app.settings.model';
import { FileUploadAttackedViewComponent } from './view/file-upload-attached-view.component';
import { MatSnackBar } from '@angular/material/snack-bar';
@Component({
  selector: 'app-file-upload-attached',
  templateUrl: './file-upload-attached.component.html'
})

export class FileUploadAttackedComponent {
  percentDone: number;
  uploadSuccess: boolean = true;
  curentFiles: FileDataDTO[] = [];
  @Input() apiUpload: string;
  @Input() label: string;
  @Input() accept: string;
  @Input() params: any;
  @Input() attribute: object = { hidden: null };
  @Input() viewPath: string;
  @Input() multiple: boolean = true;
  @Output() outData: EventEmitter<FileDataDTO> = new EventEmitter<FileDataDTO>();

  constructor(
    public dialogRef: MatDialogRef<FileUploadAttackedComponent>,
    private http: HttpClient,
    public snackBar: MatSnackBar,
    public dialog: MatDialog,
    @Inject(MAT_DIALOG_DATA)
    public message: string) {
  }

  onClick(status: boolean): void {
    this.dialogRef.close(status);
  }

  upload(files: File[]) {
    this.uploadAndProgressSingle(files);
  }

  openView(data: FileDataDTO) {
    this.dialog.open(FileUploadAttackedViewComponent, {
      data: { file: data, params: this.params, viewPath: this.viewPath },
      panelClass: 'dialog-1100',
      disableClose: true,
      autoFocus: false
    }).afterClosed().subscribe(outData => {
    });
  }

  @ViewChild('file2', { static: false }) fileX2: ElementRef;

  uploadAndProgressSingle(files: File[]) {
    this.uploadSuccess = false;
    var formData = new FormData();
    Array.from(files).forEach(f => formData.append('file', f))
    this.http.post<ApiResponseData<FileDataDTO>>(this.apiUpload, formData, { reportProgress: true, observe: 'events' }).pipe()
      .subscribe(event => {
        if (event.type === HttpEventType.UploadProgress) {
          this.percentDone = Math.round(100 * event.loaded / (event.total ?? 1));
        } else if (event instanceof HttpResponse) {
          this.uploadSuccess = true;
          this.fileX2.nativeElement.value = null;
          if (event.body?.data != null) {
            var data = event.body.data;
            this.snackBar.open(event.body.message, "", { duration: 5000 });

            if (this.curentFiles == null) {
              this.curentFiles = [];
            }
            this.curentFiles=[];
            if(data != null)
            {
              this.curentFiles.push(data);
              this.outData.emit(data);
            }
          }
        }
      });
    this.uploadSuccess = false;
  }
}