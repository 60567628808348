import { Injectable, Injector } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpEvent, HttpRequest } from '@angular/common/http';
import { shareReplay, retry, catchError } from 'rxjs/operators';
import { ApiResponseData,  ClientSettings } from './../../../app.settings.model';
import { AppClientSettings } from '../../../app.settings';
import { AppCommon } from '../../../app.common'
import { Observable } from 'rxjs';
import {CameraCommand, CameraModel} from "./camera.model";

@Injectable()
export class CameraService {
  public url = "/api/work/Camera";
  _injector: Injector;
  public clientSettings: ClientSettings;
  constructor
  (
    public http: HttpClient,
    appClientSettings: AppClientSettings,
    public appCommon :AppCommon,
    injector: Injector
  ) {
    this.clientSettings = appClientSettings.settings
    this.url = this.clientSettings.serverAPI + this.url
    this._injector = injector;
  }

  searchPaged(params: any) {
    return this.http.get<ApiResponseData<CameraModel[]>>(this.url + "/searchpage", this.appCommon.getHeaders(params)).pipe(shareReplay(1),retry(1),catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)));
  }

  create(camera: CameraCommand) {
    return this.http.post<ApiResponseData<CameraModel>>(this.url + "/create", camera).pipe(shareReplay(1),retry(1),catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)));
  }

  edit(camera: CameraCommand) {
    return this.http.put<ApiResponseData<CameraModel>>(this.url + "/edit", camera).pipe(shareReplay(1),retry(1),catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)));
  }
  getById(id: number) {
    return this.http.get<ApiResponseData<CameraModel>>(this.url + "/getById/" + id).pipe(shareReplay(1),retry(1),catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)));
  }
  delete(id: number) {
    return this.http.delete<ApiResponseData<CameraModel>>(this.url + "/delete/" + id).pipe(shareReplay(1),retry(1),catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)));
  }

  exportData(params: any) : Observable<HttpEvent<Blob>>{
    var paramsStr = new URLSearchParams(params).toString();
    return this.http.request(new HttpRequest(
      'POST', this.url + "/ExportData?"+paramsStr, null,
      { reportProgress: true,
        responseType: 'blob'
      }));

  }

  handleError(error: any, injector: Injector) {
    if (error.status === 401) {

    } else {

    }
    return Promise.reject(error);
  }
}
