import { Component, OnInit, Input, ViewChild, ViewEncapsulation } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { MatMenuTrigger } from '@angular/material/menu';
import { Menu } from '../menu.model';
import { AccountData } from 'src/app/admin/account/account.model';
import { AppStorage } from 'src/app/app.storage';

@Component({
  selector: 'app-horizontal-menu',
  templateUrl: './horizontal-menu.component.html',
  styleUrls: ['./horizontal-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class HorizontalMenuComponent implements OnInit {
  @Input('menuParentId') menuParentId;
  public menuItems:Array<any>;
  public settings: Settings;
  accountInfo: AccountData;
  roleIds: any[];

  @ViewChild(MatMenuTrigger) trigger: MatMenuTrigger;
  constructor(public appSettings:AppSettings, public menuService:MenuService, public router:Router,  public appStorage: AppStorage) { 
    this.settings = this.appSettings.settings;
    this.accountInfo = this.appStorage.getAccountInfo();
    this.roleIds = this.accountInfo.roleActions.map(x => x.id);
  }

  ngOnInit() {
    this.menuItems = this.menuService.getHorizontalMenuItems();
    this.menuItems = this.menuItems.filter(item => item.parentId == this.menuParentId);
  }

  checkRole(menu: Menu) {

    if (menu.roleAcctionIds == '99999') {
      return true;
    }
    if (this.accountInfo.isSuperAdmin) {
      return true;
    }
    if (menu.roleAcctionIds != null) {
      var ids = menu.roleAcctionIds.split(',').map(Number);
      return this.roleIds.find(x => ids.indexOf(x) >= 0) != null;
    }
    return false;
  }


  ngAfterViewInit(){
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if(this.settings.fixedHeader){
          let mainContent = document.getElementById('main-content');
          if(mainContent){
            mainContent.scrollTop = 0;
          }
        }
        else{
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }                
    });
  } 

}