import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { ApiResponseData,  AppVersionDTO,  ClientSettings, PullData } from './app.settings.model';
import { AppClientSettings } from './app.settings';
import { AppCommon } from './app.common'
@Injectable()
export class AppService {
    public url = "/api/admin/initData";
    public clientSettings: ClientSettings;
    constructor
        (
            public http: HttpClient,
            appClientSettings: AppClientSettings,
            public appCommon :AppCommon
        ) {
        this.clientSettings = appClientSettings.settings
        this.url = this.clientSettings.serverAPI + this.url
    }

    pullData(v: string) {
        return this.http.post<ApiResponseData<PullData>>(this.url + "/pulldata/" + v, {});
    }

    currentVersion() {
        return this.http.post<ApiResponseData<AppVersionDTO>>(this.url + "/CurrentVersion", {});
    }
} 