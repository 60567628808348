import { Injectable, Injector } from '@angular/core';
import {HttpClient, HttpErrorResponse, HttpHeaders} from '@angular/common/http';
import {ApiResponseData, ClientSettings} from '../app.settings.model';
import { AppClientSettings } from '../app.settings';
import { AppCommon } from '../app.common'
import {catchError, retry, shareReplay} from "rxjs/operators";

@Injectable()
export class StreamServiceService {
  _injector: Injector;
  public clientSettings: ClientSettings;
  constructor
  (
    public http: HttpClient,
    appClientSettings: AppClientSettings,
    public appCommon :AppCommon,
    injector: Injector
  ) {
    this._injector = injector;
  }

  webRtcStreamData(url: string, data: any) {
    const formData: FormData = new FormData();
    formData.append('data', data.data);
    const headers_object = new HttpHeaders();
    headers_object.append('Content-Type', 'text/html; charset=utf-8');
    headers_object.append("Authorization", "Basic " + btoa("demo:demo"));
    return this.http.post(url, formData, {
      // headers: headers_object,
      responseType: 'text'
    }).pipe(shareReplay(1),retry(1),catchError((err: HttpErrorResponse) => this.handleError(err, this._injector)));
  }

  handleError(error: any, injector: Injector) {
    console.log(error)
    return Promise.reject(error);
  }
}
