import { Component, OnInit, Input, Output, ViewEncapsulation, EventEmitter } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AccountData } from 'src/app/admin/account/account.model';
import { AppStorage } from 'src/app/app.storage';
import { AppSettings } from '../../../../app.settings';
import { Settings } from '../../../../app.settings.model';
import { MenuService } from '../menu.service';
import { Menu } from '../menu.model';

@Component({
  selector: 'app-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  encapsulation: ViewEncapsulation.None,
  providers: [ MenuService ]
})
export class VerticalMenuComponent implements OnInit {
  @Input('menuItems') menuItems;
  @Input('menuParentId') menuParentId;
  @Output() onClickMenuItem: EventEmitter<any> = new EventEmitter<any>();
  roleIds: any[];
  parentMenu: Array<Menu>;
  public settings: Settings;
  accountInfo: AccountData;
  lang: string;
  pointLang: number = 0;
  constructor(
    public appSettings: AppSettings,
    public menuService: MenuService,
    public router: Router,
    public appStorage: AppStorage,
    private translate: TranslateService
  ) {
    this.settings = this.appSettings.settings;
    this.accountInfo = this.appStorage.getAccountInfo();
    this.roleIds = this.accountInfo.roleActions.map(x => x.id);
    this.lang = this.translate.currentLang;
    if (this.lang == 'en') {
      this.pointLang = 1;
    }
    else {
      this.pointLang = 0;
    }
    this.translate.onLangChange.subscribe(() => {
      this.lang = this.translate.currentLang;
      if (this.lang == 'en') {
        this.pointLang = 1;
      }
      else {
        this.pointLang = 0;
      }
    });
  }

  ngOnInit() {
    console.log('menu data = ', this.menuItems);
    this.parentMenu = this.menuItems.filter(item => item.parentId == this.menuParentId);
    var thisPath = location.pathname;
    this.parentMenu.forEach(x=> {
      var link = x.routerLink;
      if(thisPath == link)
      {
        setTimeout(() => {
          this.menuService.toggleMenuItem(this.menuParentId);
        }, 100);
        x.isActive = true;
      }
      else
      {
        x.isActive = false;
      }
    });
  }

  splitLang(input: string) {
    if (input == null || input == undefined || input == "") {
      return "";
    }
    return input.split("|")[this.pointLang];
  }

  checkRole(menu: Menu) {

    if (menu.roleAcctionIds == '99999') {
      return true;
    }
    if (this.accountInfo.isSuperAdmin) {
      return true;
    }
    if (menu.roleAcctionIds != null) {
      var ids = menu.roleAcctionIds.split(',').map(Number);
      return this.roleIds.find(x => ids.indexOf(x) >= 0) != null;
    }
    return false;
  }

  ngAfterViewInit() {
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        if (this.settings.fixedHeader) {
          let mainContent = document.getElementById('main-content');
          if (mainContent) {
            mainContent.scrollTop = 0;
          }
        }
        else {
          document.getElementsByClassName('mat-drawer-content')[0].scrollTop = 0;
        }
      }
    });
  }

  onClick(menuId:number) {

    if(this.menuItems.filter(item => item.parentId ==menuId).length == 0)
    {
      this.parentMenu.forEach(x=> {
        x.isActive = false;
      });
    }

    this.menuService.toggleMenuItem(menuId);
    this.menuService.closeOtherSubMenus(this.menuItems, menuId);
    this.onClickMenuItem.emit(menuId);
  }

}
