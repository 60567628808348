import {Injectable, Pipe, PipeTransform} from '@angular/core';
import {HttpHeaders} from '@angular/common/http';
import {User} from './admin/user/users/users.model';
import {FormGroup} from '@angular/forms';
import {DomSanitizer, SafeHtml, SafeResourceUrl, SafeScript, SafeStyle, SafeUrl} from '@angular/platform-browser';
import {DatePipe} from '@angular/common';
import {City} from './admin/category/city/city.model';
import {District} from './admin/category/district/district.model';
import {Nation} from './admin/category/nation/nation.model';
import {Nationality} from './admin/category/nationality/nationality.model';
import {Ward} from './admin/category/ward/ward.model';
import {Rank} from './admin/category/rank/rank.model';
import {WorkPosition} from './admin/category/work-position/work-position.model';
import {WorkDepartment} from './admin/category/work-department/work-department.model';
import {EnforcementFunction} from './admin/category/enforcement-function/enforcement-function.model';
import {University} from './admin/category/university/university.model';
import {Majoring} from './admin/category/majoring/majoring.model';
import {AuditorCardIssuer} from './admin/category/auditor-card-issuer/auditor-card-issuer.model';
import * as moment from "moment/moment";
import Swal from 'sweetalert2'


@Injectable()
export class AppCommon {

  DATETIME_FORMAT_FOR_CAMERA_APP = 'YYYY-MM-DDHH:mm:ss';

  jsonToQueryString(json: object) {
    return '?' +
      Object.keys(json).map(function (key) {
        return encodeURIComponent(key) + '=' +
          encodeURIComponent(json[key]);
      }).join('&');
  }

  moneyInputFormat() {
    return {
      align: "left",
      allowNegative: true,
      allowZero: true,
      decimal: ",",
      precision: 0,
      prefix: "",
      suffix: "",
      thousands: ".",
      nullable: false
    };
  }

  changeUrl(url: string, json: object) {
    window.history.pushState('page2', 'Title', url + this.jsonToQueryString(json));
  }


  formatDateTime2(inDate: any, fm: string) {
    if (inDate == null || inDate == undefined || inDate == "null") {
      return "";
    }
    var check = (typeof inDate === 'object' && inDate !== null);
    if(check == true)
    {
      return inDate.format(fm)
    }
    else
    {
      return inDate;
    }
  }

  formatDateTime(inDate: any, fm: string) {
    if (inDate == null || inDate == undefined || inDate == "null") {
      return "";
    }
    var datePipe = new DatePipe("vi-VN");
    var data = datePipe.transform(inDate, fm);
    return data;
  }


  formatDateTimeZone(inDate: any, fm: string, zone: string) {
    if (inDate == null || inDate == undefined || inDate == "null") {
      return "";
    }
    var datePipe = new DatePipe(zone);
    var data = datePipe.transform(inDate, fm);
    return data;
  }

  dateAddDay(inDate: Date, days: number): Date {
    var newdate = new Date(inDate);
    newdate.setDate(newdate.getDate() + days);
    return newdate;
  }

  dateAddMonth(inDate: Date, months: number): Date {
    var newdate = new Date(inDate);
    newdate.setMonth(newdate.getMonth() + months);
    return newdate;
  }


  daysInMonth(year: number, month: number) {
    return new Date(year, month, 0).getDate();
  }

  searchfixParram(defaultObject: any): any {
    try {
      let copyParrams = Object.assign({}, defaultObject);
      var props = Object.getOwnPropertyNames(copyParrams);
      props.forEach(element => {
        var thisValue = copyParrams[element];
        if(thisValue != null && Array.isArray(thisValue))
        {
          copyParrams[element] = thisValue.join(',');
        }
      });
      return copyParrams;
    }
    catch
    {
      return defaultObject;
    }
  }

  public urlToJsonV2(defaultObject: any, url: string, callback: (paramName:any, value: any) => any): any {

    if (url == null || url == undefined || url == "") {
      return defaultObject;
    }
    try {
      var hash: any[];
      var myJson = {};
      var hashes = url.slice(url.indexOf('?') + 1).split('&');
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        myJson[hash[0]] = callback(hash[0],decodeURIComponent(hash[1]));
      }
      return myJson;
    }
    catch
    {
      return defaultObject;
    }
  }
  urlToJson(defaultObject: any, url: string): any {

    if (url == null || url == undefined || url == "") {
      return defaultObject;
    }
    try {
      var hash: any[];
      var myJson = {};
      var hashes = url.slice(url.indexOf('?') + 1).split('&');
      for (var i = 0; i < hashes.length; i++) {
        hash = hashes[i].split('=');
        myJson[hash[0]] = decodeURIComponent(hash[1]);
      }
      return myJson;
    }
    catch
    {
      return defaultObject;
    }
  }

  urlToBasePath(url: string) {

    var hashes = url.split('?');
    if (url.length > 1) {
      return hashes[0];
    }
    return url;
  }

  initHeightTable(tagid: string, trudi: number): number {
    let getTop = document?.getElementById(tagid)?.getBoundingClientRect()?.top;
      return window.innerHeight - (getTop ?? 0) - trudi;
  }

  getHeaders(params: any) {
    let myHeaders = new HttpHeaders();
    myHeaders.append('Content-Type', 'application/json');
    return {
      params: this.cleanOject(params), headers: myHeaders
    }
  }

  getHeadersText(params: any) {
    return {
      params: this.cleanOject(params),
      headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
      responseType: 'text'
    }
  }

  getHeadersDowload() {
    return new HttpHeaders({
      'Content-Type': 'application/pdf',
      responseType: 'blob',
      Accept: 'application/xlxs',
      observe: 'response'
    })
  }

  cleanOject(obj: any) {
    for (var propName in obj) {
      if (obj[propName] === null || obj[propName] === "null" || obj[propName] === undefined) {
        delete obj[propName];
      }
    }
    return obj;
  }

  pad(number: number, length: number) {
    var str = '' + number;
    while (str.length < length) {
      str = '0' + str;
    }
    return str;
  }

  toAfterMoney(input: number, discountAmount: number, discountUnitType: number) {
    if (discountUnitType == 0) {
      return parseFloat((input - discountAmount).toString()).toFixed(0)
    }
    else {
      return parseFloat((input - ((discountAmount * input) / 100)).toString()).toFixed(0)
    }
  }

  toAfterMoneyStr(input: number, discountAmount: number, discountUnitType: number) {
    var total = "";
    if (discountUnitType == 0) {
      total = parseFloat((input - discountAmount).toString()).toFixed(0);
    }
    else {
      total = parseFloat((input - ((discountAmount * input) / 100)).toString()).toFixed(0)
    }
    if (total == "") {
      return "";
    }
    return this.formatMoney(parseInt(total), 0, 3);
  }

  formatMoney(value: number, n:any, x:any) {
    if (value == null) {
      return "0";
    }
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\,' : '$') + ')';
    return value.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&.');
  }

  fMoney(value: number) {
    var n = 0;
    var x = 3;
    if (value == null) {
      return "0";
    }
    var re = '\\d(?=(\\d{' + (x || 3) + '})+' + (n > 0 ? '\\,' : '$') + ')';
    return value.toFixed(Math.max(0, ~~n)).replace(new RegExp(re, 'g'), '$&.');
  }

  getUser(users: User[], id: number) {
    if (id == null || id == 0) {
      return "---";
    }
    var dl = users.find(x => x.id == id);
    if (dl != null) {
      return dl.displayName;
    }
    else {
      return "---";
    }
  }

  isDisabledControll(form: FormGroup, controlName: string, value: boolean) {
    if (value) {
      form?.get(controlName)?.disable();
    } else {
      form?.get(controlName)?.enable();
    }
  }

  getColor(items: any, id: number) {
    if (id == null || id == 0) {
      return "---";
    }
    var dl = items.find(x => x.id == id);

    if (dl != null) {
      return dl.name;
    }
    else {
      return "---";
    }
  }

  getModel(items: any, id: number) {
    if (id == null || id == 0) {
      return "---";
    }
    var dl = items.find(x => x.id == id);

    if (dl != null) {
      return dl.name;
    }
    else {
      return "---";
    }
  }

  getObject(items: any, id: number) {
    if (id == null || id == 0) {
      return null;
    }
    if (items?.length === 0) {
      return null;
    }
    return items.find(x => x.id == id);
  }

  getObj(items: any, id: number) {
    if (id == null || id == 0) {
      return "---";
    }
    var dl = items.find(x => x.id == id);

    if (dl != null) {
      return dl.name;
    }
    else {
      return "---";
    }
  }

    getObjString(items: any, id: string) {
      if (id == null || id == "") {
        return "---";
      }
      var dl = items.find(x => x.id == parseInt(id));
      if (dl != null) {
        return dl.name;
      }
      else {
        return "---";
      }
    }

  openLiveUrlCamera(element: any, isLive: boolean = true, fromDate = null, toDate = null, type = 1, brand : any = null, token = '') {
    let startTime = moment().format(this.DATETIME_FORMAT_FOR_CAMERA_APP);
    let url = 'opencamera:'+element.id;
    if (type === 2) {
      url = 'playback:'+element.id;
    }
    if (type === 3) {
      url = 'download:'+element.id;
    }
    if (brand === null) {
      this.showErrorMessage('Không xác định được hãng Camera!');
      return;
    }
    if (!brand.isLiveSupport) {
      this.showErrorMessage('Hệ thống chưa hỗ trợ camera hãng: ' + brand.name)
      return;
    }
    url = brand.scheme + url;
    if (!isLive) {
      url +=  '|' + fromDate + '|' + toDate
    } 
    window.location.href = url + '|' + token;
    console.log(url + '|' + token);
    return;

    // let startTime = moment().format(this.DATETIME_FORMAT_FOR_CAMERA_APP);
    // let url = element.rtsp
    //   + '|' + element.natIp
    //   + '|' + element.cameraInfoUser
    //   + '|' + element.cameraInfoPassword
    //   + '|' + element.natSdkPort
    //   + '|' + element.cameraInfoChannel;
    // if (brand === null) {
    //   this.showErrorMessage('Không xác định được hãng Camera!');
    //   return;
    // }
    // if (!brand.isLiveSupport) {
    //   this.showErrorMessage('Hệ thống chưa hỗ trợ camera hãng: ' + brand.name)
    //   return;
    // }
    // url = brand.scheme + type + '|' + url;
    // if (isLive) {
    //   url +=  '|' + startTime + '|' + startTime
    // } else {
    //   url +=  '|' + fromDate + '|' + toDate
    // }
    // window.location.href = url;
    // console.log(url)
    // return;
  }

  showErrorMessage(message, title = 'Lỗi!', closeButton = 'Đóng') {
    Swal.fire({
      title: title,
      text: message,
      icon: 'error',
      confirmButtonText: closeButton
    });
  }

  vietnameseStringToSlug(str) {
    var from = "àáãảạăằắẳẵặâầấẩẫậèéẻẽẹêềếểễệđùúủũụưừứửữựòóỏõọôồốổỗộơờớởỡợìíỉĩịäëïîöüûñçýỳỹỵỷ",
      to   = "aaaaaaaaaaaaaaaaaeeeeeeeeeeeduuuuuuuuuuuoooooooooooooooooiiiiiaeiiouuncyyyyy";
    for (var i=0, l=from.length ; i < l ; i++) {
      str = str.replace(RegExp(from[i], "gi"), to[i]);
    }
    str = str.toLowerCase()
      .trim()
      .replace(/[^a-z0-9\-]/g, '-')
      .replace(/-+/g, '-');

    return str;
  }

}

@Pipe({ name: 'citiesFilter' })
@Injectable()
export class CitiesFilter implements PipeTransform {
  transform(datas: City[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}

@Pipe({ name: 'wardsFilter' })
@Injectable()
export class WardsFilter implements PipeTransform {
  transform(datas: Ward[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas.filter(x => x.cityId == o.cityId && x.districtId == o.districtId);;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 && x.cityId == o.cityId && x.districtId == o.districtId);
  }
}


@Pipe({ name: 'nationalitiesFilter' })
@Injectable()
export class NationalitiesFilter implements PipeTransform {
  transform(datas: Nationality[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}

@Pipe({ name: 'nationsFilter' })
@Injectable()
export class NationsFilter implements PipeTransform {
  transform(datas: Nation[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}


@Pipe({ name: 'districtsFilter' })
@Injectable()
export class DistrictsFilter implements PipeTransform {
  transform(datas: District[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas.filter(x => x.cityId == o.cityId);;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 && x.cityId == o.cityId);
  }
}

@Pipe({ name: 'userFilter' })
@Injectable()
export class UserFilter implements PipeTransform {
  transform(users: User[], key: string): any {
    if (key == null || key == undefined || key == "") {
      return users;
    }
    return users.filter(treatmentService => treatmentService.displayName.toLowerCase().indexOf(key.toLowerCase()) !== -1);
  }
}

@Pipe({ name: 'ranksFilter' })
@Injectable()
export class RanksFilter implements PipeTransform {
  transform(datas: Rank[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}

@Pipe({ name: 'workPositionsFilter' })
@Injectable()
export class WorkPositionsFilter implements PipeTransform {
  transform(datas: WorkPosition[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}

@Pipe({ name: 'workDepartmentsFilter' })
@Injectable()
export class WorkDepartmentsFilter implements PipeTransform {
  transform(datas: WorkDepartment[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}


@Pipe({ name: 'auditorCardIssuersFilter' })
@Injectable()
export class AuditorCardIssuersFilter implements PipeTransform {
  transform(datas:  AuditorCardIssuer[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}

@Pipe({ name: 'majoringsFilter' })
@Injectable()
export class MajoringsFilter implements PipeTransform {
  transform(datas: Majoring[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}

@Pipe({ name: 'enforcementFunctionsFilter' })
@Injectable()
export class EnforcementFunctionsFilter implements PipeTransform {
  transform(datas: EnforcementFunction[], o: any): any {
    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}

@Pipe({ name: 'universitysFilter' })
@Injectable()
export class UniversitysFilter implements PipeTransform {
  transform(datas: University[], o: any): any {
    datas = datas.filter(x=>x.group == o.group && x.type == o.type);

    if (o == null || o == undefined || o == "" || o.key == "" || o.key == null|| o.key == undefined) {
      return datas;
    }
    return datas.filter(x => x.name?.toLowerCase().indexOf(o.key?.toLowerCase()) !== -1 );
  }
}




@Pipe({
  name: 'power'
})
export class PowerPipe implements PipeTransform {
  transform(base: number, exponent: number): number {
    return Math.pow(base, exponent);
  }
}

@Pipe({ name: 'safeHtml' })
export class Safe {
  constructor(private sanitizer: DomSanitizer) { }
  transform(value: string, type: string): SafeHtml | SafeStyle | SafeScript | SafeUrl | SafeResourceUrl {
    switch (type) {
      case 'html':
        return this.sanitizer.bypassSecurityTrustHtml(value);
      case 'style':
        return this.sanitizer.bypassSecurityTrustStyle(value);
      case 'script':
        return this.sanitizer.bypassSecurityTrustScript(value);
      case 'url':
        return this.sanitizer.bypassSecurityTrustUrl(value);
      case 'resourceUrl':
        return this.sanitizer.bypassSecurityTrustResourceUrl(value);
      default:
        return this.sanitizer.bypassSecurityTrustHtml(value);
    }
  }
}
