import { Component, OnInit, Inject } from '@angular/core';
import { DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE } from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder} from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import { AppCommon } from 'src/app/app.common';
import { AppStorage } from 'src/app/app.storage';
import { ClientSettings, FileDataDTO } from 'src/app/app.settings.model';
import { HttpClient } from '@angular/common/http';
import { AppClientSettings } from 'src/app/app.settings';

@Component({
  selector: 'app-file-upload-attached-view.component',
  templateUrl: './file-upload-attached-view.component.html',
  providers: [
    AppCommon,
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})

export class FileUploadAttackedViewComponent implements OnInit {
  public form: FormGroup;
  public formCalendars: FormGroup;
  public categoryData: any;
  baseTranslate: any;
  isProcess = { saveProcess: false, deleteProcess: false, initData: false, accountSearching: false };
  pathView: string;
  public clientSettings: ClientSettings;

  constructor(
    public dialogRef: MatDialogRef<FileUploadAttackedViewComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: { file:FileDataDTO, viewPath: string, params: any},
    public fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public appStorage: AppStorage,
    public appCommon: AppCommon,
    private http: HttpClient,
    appClientSettings: AppClientSettings,
  ) {
    this.clientSettings = appClientSettings.settings;
  }

  ngOnInit() {
    this.pathView = this.clientSettings.serverAPI + this.data.file.path;
  }

  getData() {
    this.isProcess.initData = true;
  }

  close(input: any = null): void {
    if (input != null) {
      this.dialogRef.close(input);
    }
    else {
      this.dialogRef.close();
    }
  }
}
