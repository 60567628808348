import { Injectable, Inject} from '@angular/core';
import { LOCAL_STORAGE, StorageService } from 'ngx-webstorage-service';
import { AccountData} from "./admin/account/account.model"
import { PullData } from './app.settings.model';
@Injectable()
export class AppStorage {
    constructor(@Inject(LOCAL_STORAGE) private storage: StorageService) { }

    setAccountInfo(data:AccountData){
        this.storage.set("AccountInfo", data);
    }
    
    removeAccountInfo(){
        this.storage.remove("AccountInfo");
    }

    getAccountInfo(): AccountData
    {
       return this.storage.get("AccountInfo");
    }
    
    setCategoryData(data:PullData){
        this.storage.set("PullData", data);
    }

    removeCategoryData(){
        this.storage.remove("PullData");
    }

    getCategoryData(): PullData
    {
       return this.storage.get("PullData");
    }

    getUserId(): string
    {
       var outPut = this.storage.get("userId");
       if(outPut == null || outPut == "" || outPut == "trongdz"|| outPut == undefined)
       {
         this.setUserId("");
       }
       return this.storage.get("userId");
    }

    setUserId(userId: string){
        if(userId == null || userId == "" || userId == "trongdz"|| userId == undefined)
        {
        }
        else
        {
            this.storage.set("userId", userId);
        }
    }

    getAppVersion(): string
    {
       var outPut = this.storage.get("appVersion");
       if(outPut == null || outPut == "" || outPut == "0"|| outPut == undefined)
       {
         this.setUserId("");
       }
       return this.storage.get("appVersion");
    }

    setAppVersion(data: string){
        this.storage.set("appVersion", data);
    }
}