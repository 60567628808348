import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules  } from '@angular/router';
import { AdminComponent } from './admin/admin.component';
import { AuthGuard } from './auth/auth.guard';
import { NotEnoughAccessComponent } from './pages/not-enough-access/not-enough-accessComponent';
import { NotFoundComponent } from './pages/not-found/not-found.component';
import { RedirectComponent } from './pages/redirect/redirect.component';
import { ModuleWithProviders } from '@angular/core';

export const routes: Routes = [
    {
        path: '',
        component: AdminComponent, canActivate: [AuthGuard], children: [
            { path: '', loadChildren: () => import('./admin/dashboard/dashboard.module').then(m => m.DashboardModule), data: { breadcrumb: 'Dashboard' } },
            { path: 'admin/profiles', loadChildren: () => import('./admin/account/account.module').then(m => m.AccountModule) },
            { path: 'admin/roles', loadChildren: () => import('./admin/user/roles/roles.module').then(m => m.RolesModule), data: { breadcrumb: 'Roles' } },
            { path: 'admin/role-groups', loadChildren: () => import('./admin/user/role-groups/role-groups.module').then(m => m.RoleGroupsModule), data: { breadcrumb: '' } },
            { path: 'admin/role-areas', loadChildren: () => import('./admin/user/role-areas/role-areas.module').then(m => m.RoleAreasModule), data: { breadcrumb: '' } },
            { path: 'admin/role-controllers', loadChildren: () => import('./admin/user/role-controllers/role-controllers.module').then(m => m.RoleControllersModule), data: { breadcrumb: '' } },
            { path: 'admin/users', loadChildren: () => import('./admin/user/users/users.module').then(m => m.UsersModule), data: { breadcrumb: 'Users' } },
            { path: 'admin/report', loadChildren: () => import('./admin/report/report.module').then(m => m.ReportModule), data: { breadcrumb: '' } },

            // Danh mục
            { path: 'admin/category/city', loadChildren: () => import('./admin/category/city/city.module').then(m => m.CityModule), data: { breadcrumb: '' } },
            { path: 'admin/category/district', loadChildren: () => import('./admin/category/district/district.module').then(m => m.DistrictModule), data: { breadcrumb: '' } },
            { path: 'admin/category/enforcement-function', loadChildren: () => import('./admin/category/enforcement-function/enforcement-function.module').then(m => m.EnforcementFunctionModule), data: { breadcrumb: '' } },
            { path: 'admin/category/nation', loadChildren: () => import('./admin/category/nation/nation.module').then(m => m.NationModule), data: { breadcrumb: '' } },
            { path: 'admin/category/nationality', loadChildren: () => import('./admin/category/nationality/nationality.module').then(m => m.NationalityModule), data: { breadcrumb: '' } },
            { path: 'admin/category/ward', loadChildren: () => import('./admin/category/ward/ward.module').then(m => m.WardModule), data: { breadcrumb: '' } },
            { path: 'admin/category/work-department', loadChildren: () => import('./admin/category/work-department/work-department.module').then(m => m.WorkDepartmentModule), data: { breadcrumb: '' } },
            { path: 'admin/category/work-position', loadChildren: () => import('./admin/category/work-position/work-position.module').then(m => m.WorkPositionModule), data: { breadcrumb: '' } },
            { path: 'admin/category/majoring', loadChildren: () => import('./admin/category/majoring/majoring.module').then(m => m.MajoringModule), data: { breadcrumb: '' } },
            { path: 'admin/category/university', loadChildren: () => import('./admin/category/university/university.module').then(m => m.UniversityModule), data: { breadcrumb: '' } },
            { path: 'admin/category/auditor-card-issuer', loadChildren: () => import('./admin/category/auditor-card-issuer/auditor-card-issuer.module').then(m => m.AuditorCardIssuerModule), data: { breadcrumb: '' } },

            // Camera Management

            { path: 'admin/camera', loadChildren: () => import('./admin/work/camera/camera.module').then(m => m.CameraModule), data: { breadcrumb: '' } },
            { path: 'admin/camera-place', loadChildren: () => import('././admin/work/camera-place/camera-place.module').then(m => m.CameraPlaceModule), data: { breadcrumb: '' } },
            { path: 'admin/camera-place-category', loadChildren: () => import('././admin/work/camera-place-category/camera-place-category.module').then(m => m.CameraPlaceCategoryModule), data: { breadcrumb: '' } },
            { path: 'admin/camera-brand', loadChildren: () => import('././admin/work/camera-brand/camera-brand.module').then(m => m.CameraBrandModule), data: { breadcrumb: '' } },
            // { path: 'admin/report', loadChildren: () => import('././admin/work/report/report.module').then(m => m.ReportModule), data: { breadcrumb: '' } },

            // staff
            { path: 'admin/staff', loadChildren: () => import('./admin/work/staff/staff.module').then(m => m.StaffModule), data: { breadcrumb: '' } },
            // { path: 'admin/staff-certification', loadChildren: () => import('./admin/work/staff-certification/staff-certification.module').then(m => m.StaffCertificationModule), data: { breadcrumb: '' } },
        ]
    },
    { path: 'login', loadChildren: () => import('./admin/account/login/login.module').then(m => m.LoginModule) },
    { path: 'not-enough-access', component: NotEnoughAccessComponent, data: { breadcrumb: 'Error' } },
    { path: 'redirect', component: RedirectComponent, data: { breadcrumb: 'Error' } },
    { path: '**', component: NotFoundComponent }
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules})],
    exports: [
        RouterModule
    ]
})
export class AppRoutingModule { }

export const routing: ModuleWithProviders<any> = RouterModule.forRoot(routes, {
});
