import { Component, OnInit, Inject, HostListener, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import {DateAdapter, MAT_DATE_FORMATS, MAT_DATE_LOCALE, ThemePalette} from '@angular/material/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FormGroup, FormBuilder, Validators, FormControl } from '@angular/forms';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AppCommon } from '../../../../app.common'
import { AppStorage } from '../../../../app.storage'
import { MAT_MOMENT_DATE_FORMATS, MomentDateAdapter } from '@angular/material-moment-adapter';
import { TranslateService } from '@ngx-translate/core';
import {CameraService} from "../camera.service";
import {StreamServiceService} from "../../../../service/stream-service.service";
import * as moment from 'moment';

declare var Hls: any;

@Component({
  selector: 'app-camera-download',
  templateUrl: './camera-download.component.html',
  providers: [
    CameraService,
    StreamServiceService,
    AppCommon,
    { provide: MAT_DATE_LOCALE, useValue: 'vi-VN' },
    { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
    { provide: MAT_DATE_FORMATS, useValue: MAT_MOMENT_DATE_FORMATS }
  ]
})
export class CameraDownloadComponent implements OnInit {
  public form: FormGroup;
  public formCalendars: FormGroup;
  isProcess = { saveProcess: false, deleteProcess: false, initData: false };

  cameraInfo: any

  heightScroll: number = 600;

  type = 2;

  public disabled = false;
  public showSpinners = true;
  public showSeconds = true;
  public touchUi = false;
  public enableMeridian = false;
  public minDate: Date;
  public maxDate: Date;
  public stepHour = 1;
  public stepMinute = 1;
  public stepSecond = 1;
  public color: ThemePalette = 'primary';
  public disableMinute = false;
  public hideTime = false;

  public dateControl1 = new FormControl(new Date());
  public dateControl2 = new FormControl(new Date());

  public options = [
    { value: true, label: 'True' },
    { value: false, label: 'False' }
  ];

  public listColors = ['primary', 'accent', 'warn'];

  public stepHours = [1, 2, 3, 4, 5];
  public stepMinutes = [1, 5, 10, 15, 20, 25];
  public stepSeconds = [1, 5, 10, 15, 20, 25];


  constructor(
    public dialogRef: MatDialogRef<CameraDownloadComponent>,
    @Inject(MAT_DIALOG_DATA)
    public data: any,
    public fb: FormBuilder,
    public dialog: MatDialog,
    public snackBar: MatSnackBar,
    public appStorage: AppStorage,
    public appCommon: AppCommon,
    private translate: TranslateService,
    private streamServiceService: StreamServiceService,
    private cdr: ChangeDetectorRef
  ) {

  }

  ngOnInit() {
    if (this.data != null) {
      this.cameraInfo = this.data.cameraInfo;
      if (this.data.type) {
        this.type = this.data.type;
      }
    }
  }

  close(input: any = null): void {
    if (input != null) {
      this.dialogRef.close(input);
    }
    else {
      this.dialogRef.close();
    }
  }
  ngAfterViewInit(): void {
    this.fixHeightScroll();
  }

  onDownload() {

    const fromValue = this.dateControl1.value;
    const toValue = this.dateControl2.value;
    if (!fromValue || !toValue) {
      alert('Vui lòng nhập thời gian');
      return;
    }

    const from = moment(fromValue).format(this.appCommon.DATETIME_FORMAT_FOR_CAMERA_APP);
    const to = moment(toValue).format(this.appCommon.DATETIME_FORMAT_FOR_CAMERA_APP);

    if (!from || !to) {
      alert('Vui lòng nhập thời gian hợp lệ');
      return;
    }
    this.dialogRef.close({
      from, to
    });
  }

  // onChangeDate(date, type = 1) {
  //   console.log(date)
  //   const dateVal = date.format('YYYY-M-D');
  //   console.log(dateVal)
  //   if (type === 1) {
  //     this.fromDateTime = dateVal;
  //   } else {
  //     this.toDateTime =  dateVal;
  //   }
  // }
  @HostListener('window:resize')
  public onWindowResize(): void {
    this.fixHeightScroll();
  }

  fixHeightScroll() {
    let getTop = this.fix_detail.nativeElement.getBoundingClientRect().top;
    this.heightScroll = window.innerHeight - getTop + 20;
  }


  @ViewChild('fix_detail', { static: false }) fix_detail: ElementRef;

}
