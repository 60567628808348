import { City } from './admin/category/city/city.model';
import { District } from './admin/category/district/district.model';
import { EnforcementFunction } from './admin/category/enforcement-function/enforcement-function.model';
import { Nation } from './admin/category/nation/nation.model';
import { Nationality } from './admin/category/nationality/nationality.model';
import { Rank } from './admin/category/rank/rank.model';
import { Ward } from './admin/category/ward/ward.model';
import { WorkDepartment } from './admin/category/work-department/work-department.model';
import { WorkPosition } from './admin/category/work-position/work-position.model';
import { User } from './admin/user/users/users.model';
import { Menu } from './theme/components/menu/menu.model';

export class Settings {
  constructor(public name: string,
    public loadingSpinner: boolean,
    public fixedHeader: boolean,
    public sidenavIsOpened: boolean,
    public sidenavIsPinned: boolean,
    public sidenavUserBlock: boolean,
    public menu: string,
    public menuType: string,
    public theme: string,
    public rtl: boolean) { }
}

export class ClientSettings {
  constructor(public serverAPI: string, public serverReport) { }
}
export class ApiResponseData<T> {
  totalRecord: number;
  page: number;
  limit: number;
  data: T;
  status: boolean;
  message: string;
  language: string;
}


export class PullData {
  roles: [];
  roleTypes: [];
  categoryVersion: string;
  roleControllers: [];
  imagesMaxSize: string;
  imagesType: string;
  adminMenus: Menu[];
  cities: City[];
  districts: District[];
  wards: Ward[];
  enforcementFunctions: EnforcementFunction[];
  nationalities: Nationality[];
  nations: Nation[];
  ranks: Rank[];
  workDepartments: WorkDepartment[];
  workPositions: WorkPosition[];
}

export class FileDataDTO {
 path: string;
 fileName: string;
 createdDate: Date;
 createdUser: Date;
 fileSize: number;
 data: any;
}

export class AppVersionDTO {
id: number;
 name: string;
 note: string;
 }
