import { Component } from '@angular/core';
import {
  Event,
  NavigationCancel,
  NavigationEnd,
  NavigationError,
  NavigationStart,
  Router
} from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { AppCommon } from './app.common';
import { AppService } from './app.service';
import { AppClientSettings, AppSettings } from './app.settings';
import { Settings } from './app.settings.model';
import { AppStorage } from './app.storage';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [
    AppService,
    AppCommon,
    AppClientSettings
  ]
})
export class AppComponent {
  public settings: Settings;
  constructor(
    public appSettings: AppSettings,
    public appStorage: AppStorage,
    public appService: AppService,
    private translate: TranslateService,
    private router: Router
  ) {
    this.settings = this.appSettings.settings;
    translate.setDefaultLang('vi');

    this.router.events.subscribe((event: Event) => {
      switch (true) {
        case event instanceof NavigationStart: {
          this.settings.loadingSpinner = true; 
          break;
        }
        case event instanceof NavigationEnd:
        case event instanceof NavigationCancel:
        case event instanceof NavigationError: {
          this.settings.loadingSpinner = false; 
          break;
        }
        default: {
          break;
        }
      }
    });


  }

  ngOnInit() {
  }
}